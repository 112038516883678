@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  outline: none;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  color: #fff;
  font-size: 16px;
}

body,
html {
  background-color: #000000;
}

.page-enter {
  opacity: 0;
}
.page-enter-active {
  opacity: 1;
  transition: opacity 1s cubic-bezier(0.3, 0.7, 0.3, 1);
}
.page-exit {
  opacity: 1;
}
.page-exit-active {
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.3, 0.7, 0.3, 1);
}

strong {
  font-weight: bold;
}
